import React from 'react';
import '../styling/header.css'; // Import your CSS file for styling
import { Link } from 'react-router-dom';


const BannerHeader = () => {
  return (
    <header className="app-header">
      {/* Link to the home route */}
      <Link to="/" className="app-name-link">
        <h1 className="app-name">Most Likely To</h1>
      </Link>
    </header>
  );
};

export default BannerHeader;
