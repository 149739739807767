import React, { useEffect, useState } from "react";
import BarChart from '../components/BarChart';
import CopyToClipboardLink from '../components/CopyToClipboardLink';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Stack, Flex } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';


const GameOver = () => {
  const { gameIdParam } = useParams();
  const gameId = decodeURIComponent(gameIdParam);
  const [responseData, setResponseData] = useState({});

  const createParams = (responseDataRaw) => {
    const responsesArr = responseData.responses;
    const questionIdsArr = responseData.questionIds;

    var result = [];
    for (var i = 0; i < responsesArr.length; i++) {
      const data = [];
      const currResponse = responsesArr[i];
      for (const [key, value] of Object.entries(currResponse)) {
        data.push({label: key, value: value});
      }
      result.push({data: data, questionId: questionIdsArr[i]})
    }
    return result;
  }

  useEffect(() => {
    // Make a GET request when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/games/${gameId}`);
        setResponseData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [gameId]);

  if (responseData && responseData.responses) {
    const paramsArr = createParams(responseData);

    return (
      <Flex height="100vh" justify="center">
        <Helmet>
          <title>Most Likely To - Game Results</title>
        </Helmet>
        <Container maxW="100vh">
          <Stack spacing={4}>
            <CopyToClipboardLink type="play" gameId={gameId} />

            {paramsArr.map((currParam) => (
              <BarChart key={currParam.questionId} data={currParam.data} questionId={currParam.questionId} />
            ))}

            <CopyToClipboardLink type="gameover" gameId={gameId} />
          </Stack>
        </Container>
      </Flex>
    )
  }

  return (
      <div>
          <h1>Welcome to GameOver</h1>
      </div>
  );
};

export default GameOver;
