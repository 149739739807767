import './App.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages/home";
import Play from "./pages/play";
import GameOver from "./pages/gameover";
import BannerHeader from "./components/BannerHeader";
import { ChakraProvider } from '@chakra-ui/react';

function App() {
  return (
    <ChakraProvider>
      <Router>
        <BannerHeader />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/play/:gameIdParam/:questionNum?" element={<Play />} />
          <Route path="/gameover/:gameIdParam" element={<GameOver />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
