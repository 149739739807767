import React, { useEffect, useState } from "react";
import { useParams, useLocation, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import QuestionComponent from '../components/QuestionComponent';
import CopyToClipboardLink from '../components/CopyToClipboardLink';
import { Container, Flex } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

const Play = () => {
  const { gameIdParam, questionNum } = useParams(); // questionNum is undefined is not specified. We should assume user is on question 0
  const gameId = decodeURIComponent(gameIdParam);
  const [responseData, setResponseData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Make a GET request when the component mounts
    const fetchData = async () => {
      try {
        const optionalGame = location.state;
        if (optionalGame) {
          setResponseData(optionalGame);
        } else {
          const response = await axios.get(`/api/games/${gameId}`);
          setResponseData(response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [gameId]);

  if (responseData && responseData.questionIds && responseData.id) {
    var id = questionNum === undefined ? 0 : questionNum;
    var questionIdArr = responseData.questionIds
    if (id < questionIdArr.length) {
      var questionId = questionIdArr[id];
      return (
        <Flex height="100vh" justify="center">
          <Helmet>
            <title>Most Likely To - Play Game</title>
          </Helmet>
          <Container maxW="50vh">
            <CopyToClipboardLink type="play" gameId={gameId} />

            <QuestionComponent key={questionId} questionId={questionId} questionNum={id} answerOptions={responseData.players} gameId={responseData.id} />
        </Container>
      </Flex>
      );
    } else {
      var url = `/gameover/${gameId}`;
      return <Navigate to={url} />;
    }
  }
  return (
    <div>
        <h1>Welcome to Play</h1>
        <p>Loading...</p>
    </div>
  );
};

export default Play;
