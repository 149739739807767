import React, { useEffect, useState } from 'react';
import { Box, Text, Stack, Button } from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const QuestionComponent = ({ gameId, questionNum, questionId, answerOptions }) => {
  const [question, setQuestion] = useState('');
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    // Fetch question and answer options when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/questions/${questionId}`);

        if (response && response.data) {
          const question = response.data.name;
          setQuestion(question);
        }

      } catch (error) {
        console.error('Error fetching question:', error);
      }
    };

    fetchData();
  }, [questionId]);

  const handleSubmission = async (answer) => {
    try {

      // Make a POST request to your server with the selected answer
      const response = await axios.post(`/api/games/${gameId}`, {
        questionId: questionId,
        player: answer,
      });

      navigate(`/play/${gameId}/${parseInt(questionNum)+1}`)

      // You can handle the response as needed, e.g., show a success message
    } catch (error) {
      console.error('Error submitting response:', error);
      // Handle the error, e.g., show an error message to the user
    }
  };

  return (
    <Box p={4} borderWidth="1px" borderRadius="lg">
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        {question}
      </Text>
      <Stack spacing={2}>
        {answerOptions.map((answer, index) => (
          <Button
            key={index}
            size="lg"
            variant={selectedAnswer === answer ? 'solid' : 'outline'}
            colorScheme="teal"
            mb={2}
            onClick={() => handleSubmission(answer)}
          >
            {answer}
          </Button>
        ))}
      </Stack>
    </Box>
  );
};

/*
{answerOptions.map((answer, index) => (
  <Radio
    key={index}
    value={answer}
    isChecked={selectedAnswer === answer}
    onChange={() => setSelectedAnswer(answer)}
    borderColor="teal.500"
    borderWidth="2px"
    borderRadius="md"
    padding={2}
    _hover={{ borderColor: 'teal.300' }}
    _focus={{ borderColor: 'teal.500', boxShadow: 'outline' }}
  >
    {answer}
  </Radio>
*/

export default QuestionComponent;
