import React, { useState } from 'react';
import { Button, Flex } from '@chakra-ui/react';

const CopyToClipboardLink = ({ type, gameId }) => {
  const [isCopied, setCopied] = useState(false);

  const types = {
    play: {
      url: window.location.origin + `/play/${gameId}`,
      txt: "Copy Link to Share with Other Players"
    },
    gameover: {
      url: window.location.origin + `/gameover/${gameId}`,
      txt: "Copy Link to Share Results"
    }
  }

  const handleCopyToClipboard = () => {
    const url = types[type].url;
    navigator.clipboard.writeText(url);
    setCopied(true);

    // Reset the "Copied" state after a short delay
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <Flex justify="center" style={{ marginBottom: 15 }}>
      <Button onClick={handleCopyToClipboard} colorScheme="purple">
        {isCopied ? 'Copied!' : types[type].txt}
      </Button>
    </Flex>
  );
};

export default CopyToClipboardLink;
