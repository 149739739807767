import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Box, Text } from '@chakra-ui/react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import axios from 'axios';

const BarChart = ({ data, questionId }) => {
  const [question, setQuestion] = useState('');

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  useEffect(() => {
    // Fetch question and answer options when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/questions/${questionId}`);

        if (response && response.data) {
          const question = response.data.name;
          setQuestion(question);
        }

      } catch (error) {
        console.error('Error fetching question:', error);
      }
    };

    fetchData();
  }, [questionId]);


  const chartData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        label: 'Values',
        data: data.map((item) => item.value),
        backgroundColor: [
          'rgba(54, 162, 235, 0.6)'
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
            stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
  };

  if (question) {
    return (
      <Box p={4} borderWidth="1px" borderRadius="lg">
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          {question}
        </Text>
        <div>
          <Bar data={chartData} options={options} />
        </div>
      </Box>
    );
  }

  return (
    <div>
      <Bar data={chartData} options={options} />
    </div>
  )
};

export default BarChart;
