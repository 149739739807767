import React, { useState } from "react";
import { Container, Button, Input, Stack, Flex, Alert, AlertIcon, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Home = () => {
  const NUM_PLAYERS_DEFAULT = 3;
  const initState = () =>  {
    var result = [];
    for (var i = 0; i < NUM_PLAYERS_DEFAULT; i++) {
      result.push({id: i+1, value: ''});
    }
    return result;
  }

  const [inputFields, setInputFields] = useState(initState());
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleAddField = () => {
    setInputFields((prevInputFields) => [
      ...prevInputFields,
      { id: prevInputFields.length + 1, value: '' },
    ]);
  };

  const handleInputChange = (id, event) => {
    const newInputFields = inputFields.map((field) =>
      field.id === id ? { ...field, value: event.target.value } : field
    );
    setInputFields(newInputFields);
  };

  const handleRemoveField = (id) => {
    const newInputFields = inputFields.filter((field) => field.id !== id);
    setInputFields(newInputFields);
  };

  const handleGetInputs = async () => {
    const anyEmptyField = inputFields.some((field) => field.value.trim() === '');

    if (anyEmptyField) {
      setError('Please fill in all input fields.');
      return;
    }

    setError('');

    const allInputValues = inputFields.map((field) => field.value);

    try {
      const response = await fetch('/api/games', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ players: allInputValues }),
      });

      if (response.ok) {
        const responseBody = await response.json();

        const gameId = responseBody.id
        const encodedData = encodeURIComponent(gameId);
        navigate(`/play/${encodedData}`, {state: responseBody});
      } else {
        console.error('Create game POST request failed');
      }
    } catch (error) {
      console.error('Error during Create game POST request:', error);
    }
  };

  return (
    <Flex height="100vh" justify="center">
      <Helmet>
        <title>Most Likely To - Create Game</title>
      </Helmet>
      <Container maxW="50vh">
        <Heading size="lg" mb="4">
          Enter the Players
        </Heading>
        <Stack spacing={4}>
          {inputFields.map((field) => (
            <Stack key={field.id} direction="row" align="center">
              <Input
                type="text"
                value={field.value}
                onChange={(event) => handleInputChange(field.id, event)}
              />
              <Button colorScheme="red" onClick={() => handleRemoveField(field.id)} >
                -
              </Button>
            </Stack>
          ))}
          <Button colorScheme="purple" onClick={handleAddField}>
            Add Player
          </Button>
          <Button colorScheme="blue" onClick={handleGetInputs}>
            Create Game
          </Button>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
        </Stack>
      </Container>
    </Flex>
  );
};

export default Home;
